.progress-bar {
  -webkit-appearance: none;
  appearance: none;

  &::-moz-progress-bar {
    background-color: #a2a3a1;
  }

  &::-webkit-progress-bar {
    @apply bg-gray;
  }

  &::-webkit-progress-value {
    background-color: #a2a3a1;
  }
}
