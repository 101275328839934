@import '../components/Spinner/Spinner.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* uni-neue-regular - latin */
@font-face {
  font-family: 'Uni Neue';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/fonts/UniNeue/UniNeue-Regular.woff2') format('woff2'),
    url('/fonts/UniNeue/UniNeue-Regular.woff') format('woff');
}

/* uni-neue-bold - latin */
@font-face {
  font-family: 'Uni Neue';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/UniNeue/UniNeue-Bold.woff2') format('woff2'),
    url('/fonts/UniNeue/UniNeue-Bold.woff') format('woff');
}

/* uni-neue-book - latin */
@font-face {
  font-family: 'Uni Neue';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/UniNeue/UniNeue-Book.woff2') format('woff2'),
    url('/fonts/UniNeue/UniNeue-Book.woff') format('woff');
}

html {
  @apply h-full;
}

body {
  @apply h-full text-base font-normal 2xl:text-xl;
}

/* main > *:not(:first-child) {
  @apply my-14 md:my-28;
} */

h1 {
  @apply mb-4 text-h2-mobile font-medium sm:text-h2-desk 2xl:text-h2-large;
}
h2 {
  @apply mb-4 text-h2-mobile font-medium text-red sm:text-h2-desk 2xl:text-h2-large;
}
h3 {
  @apply mb-4 text-h3-mobile font-medium sm:text-h3-desk 2xl:text-h3-large;
}
h4 {
  @apply mb-4 text-h4-mobile font-bold sm:text-h4-desk 2xl:text-h4-large;
}

p {
  @apply mb-4 text-base sm:mb-6 2xl:text-xl;
}

a {
  @apply font-medium leading-4 2xl:text-xl;
}

p a {
  @apply font-normal underline;
}

.container {
  max-width: 100%;
}

.container img {
  object-fit: cover;
}

.text-container {
  max-width: 34vw;
  margin-left: auto;
  margin-right: auto;
}

@layer utilities {
  .pan-y {
    touch-action: pan-y;
  }

  .snap-x {
    scroll-snap-type: x;
  }

  .snap-align-center {
    scroll-snap-align: center;
  }

  .z-negative {
    z-index: -1;
  }
}

.modal-shadow {
  box-shadow: 0px 40px 32px rgba(0, 0, 0, 0.3);
}

@keyframes banner-0 {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes banner-1 {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes banner-2 {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(200%);
  }
}

.banner-animation-0 {
  animation: banner-0 30s linear infinite;
}

.banner-animation-1 {
  animation: banner-1 30s linear infinite;
}

.banner-animation-2 {
  animation: banner-2 30s linear infinite;
}

.banner-animation-0-reverse {
  animation: banner-0 30s linear infinite reverse;
}

.banner-animation-1-reverse {
  animation: banner-1 30s linear infinite reverse;
}

.banner-animation-2-reverse {
  animation: banner-2 30s linear infinite reverse;
}

.animate-button > .target {
  --clip: inset(0% 100% 0% 0%);
  -webkit-clip-path: var(--clip);
  clip-path: var(--clip);
  transition: clip-path 0.25s ease, -webkit-clip-path 0.25s ease;
}

@media screen and (min-width: 1024px) {
  .animate-button:hover > .target {
    --clip: inset(0 0 0 0);
  }
}

@keyframes loader {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.loader-animation {
  animation: loader linear 0s 1;
}

.animate-arrow svg {
  transform: translateX(0%);
  transition: transform 0.2s linear;
}

.animate-arrow:hover svg,
.animate-arrow:focus svg {
  transform: translateX(70%);
}
.animate-arrow-large svg {
  transform: translateX(0%) scale(1.4);
  transition: transform 0.2s linear;
}

.animate-arrow-large:hover svg,
.animate-arrow-large:focus svg {
  transform: translateX(70%) scale(1.4);
}

/*::::::::::::::::::::slick animation::::::::::::::::::::*/

.animated .slick-slide {
  transform: scale(0.8);
  transition: transform 500ms ease-in-out;
  opacity: 0.5;
}

.animated .slick-active {
  transform: scale(1);
  opacity: 1;
}

html {
  height: -webkit-fill-available;
}

body {
  @apply max-h-screen;
  min-height: -webkit-fill-available;
}

.small-teaser-slider .slick-track {
  @apply mx-0;
}

.small-teaser-slider .slick-slide.slick-active {
  @apply lg:max-w-[33.333%];
}

.teaser-line-clamp-none p {
  @apply line-clamp-none;
}

.teaser-line-clamp-none {
  @apply line-clamp-none;
}

.teaser-line-clamp-3 p {
  @apply line-clamp-3;
}

.teaser-line-clamp-3 {
  @apply line-clamp-3;
}

/*::::::::::::::::::::accordion-item-images::::::::::::::*/

.accordion-item figure[data-content-type='image'] {
  @apply mx-auto max-w-[75%];
}

.accordion-item-mobile figure[data-content-type='image'] > span:nth-child(1) {
  display: none !important;
}

.accordion-item-mobile figure[data-content-type='image'] > span:nth-child(2) {
  display: block !important;
}

.accordion-item-desktop figure[data-content-type='image'] > span:nth-child(2) {
  display: none !important;
}

.accordion-item-desktop figure[data-content-type='image'] > span:nth-child(1) {
  display: block !important;
}

/*::::::::::::::::::::nav animation::::::::::::::::::::*/

.nav-animation::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 0.55px;
  background-color: black;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.nav-animation:hover::after,
.nav-animation:active::after,
.nav-animation:focus::after {
  transform: scale(1);
}

.nav-animation-final::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 0.55px;
  background-color: black;
  opacity: 1;
  transform-origin: center;
}

/*::::::::::::::::::::Footer Accordion::::::::::::::::::::*/

.footer-accordion {
  @apply text-black-light;
}

.footer-accordion h4 {
  font-family: 'Uni Neue';
  @apply text-xl font-medium leading-7;
}

.footer-accordion div:first-child button {
  @apply border-t-0;
}

.react-player video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.pagebuilder-mobile-hidden {
  @apply px-20;
}

h1,
h2,
h3,
h4,
.hyphens-auto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  @apply break-words;
}

/*::::::::::::::::::::Product Slider::::::::::::::::::::*/

.product-slider .swiper-slide {
  @apply h-auto;
}

.product-slider .no-slide .swiper-slide {
  @apply md:!w-1/4;
}
/*::::::::::::::::::::FTAPI::::::::::::::::::::*/
#container {
  display: flex;
  flex-direction: column;
}

#app {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}
