.range-input {
  @apply w-full rounded;
  -webkit-appearance: none;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));

  &::-webkit-slider-runnable-track {
    @apply h-2 rounded bg-gray;
    background: linear-gradient(#c01129, #c01129) 0 / var(--sx) 100% no-repeat #e0e0e0;
  }

  &::-moz-range-track {
    @apply h-2 rounded bg-gray;
  }

  &::-moz-range-progress {
    @apply h-2 rounded bg-red-dark;
  }

  &::-webkit-slider-thumb {
    @apply relative -mt-2 h-6 w-6 rounded-full border border-red bg-red text-white;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    @apply relative h-6 w-6 rounded-full border border-red bg-red text-white;
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }
}
