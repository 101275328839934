.pdp-html-module {
  @apply text-black-light;

  h2 {
    @apply mb-2 text-xl font-bold;
  }

  p {
    @apply mb-6;
  }

  ul {
    @apply mb-6 list-disc pl-4;
  }

  a {
    @apply font-normal underline;
  }

  ul li {
    @apply mb-2;
  }

  .link-list {
    @apply list-none pl-0;

    li {
      @apply mb-4;
    }

    a {
      @apply font-bold;
    }
  }

  table {
    @apply mb-6 w-full;
  }

  th {
    @apply text-left;
  }

  td,
  th {
    @apply py-2;
  }

  tr {
    @apply border-b border-gray;
  }

  tbody {
    tr:last-of-type {
      @apply border-0;
    }
  }
}

.technical-data {
  td {
    @apply py-4;
  }

  td:first-of-type {
    @apply font-bold;
  }

  tbody {
    tr:last-of-type {
      @apply border-b border-gray;
    }
  }
}

.standard-addons {
  table {
    @apply mb-10;
  }

  thead tr {
    @apply border-b-2 border-black;
  }

  th {
    @apply text-xl;
  }

  a {
    @apply font-bold underline;
  }
}
