.spinner {
  @apply relative h-5 w-5;
}

.double-bounce1,
.double-bounce2 {
  @apply absolute top-0 left-0 h-full w-full rounded-full bg-red opacity-60;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
