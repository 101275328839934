.overflow-tabs {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tabnavi-accordion .acc-button {
  @apply px-4;
}
