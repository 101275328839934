/* ::::::::::::::::::::: sally-alignment ::::::::::::::::::::: */

.sally-align-right {
  @apply flex-row items-center justify-center;
}

.sally-align-left {
  @apply flex-row-reverse items-center justify-center;
  transform: translateX(-100%) translateX(+24px);
}

.sally-align-top {
  @apply -translate-x-1/2 -translate-y-1/2 flex-col-reverse items-center justify-between;
}

.sally-align-bottom {
  @apply flex-col items-center justify-between;
  transform: translateX(-50%) translateX(+12px) translateY(+12px);
}

.sally-align-bottom .sally-arm,
.sally-align-top .sally-arm {
  @apply rotate-90;
}

/* ::::::::::::::::::: sally-line-animation ::::::::::::::::::: */

.sally-link .sally-arm::before {
  @apply absolute top-0 left-0 z-50 h-full w-0 bg-red-light duration-700;
  content: '';
}

.sally-align-left .sally-arm::before,
.sally-align-top .sally-arm::before {
  @apply right-0 left-auto;
}

.sally-link:hover .sally-arm::before {
  @apply w-full;
}
