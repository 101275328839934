.image {
  @apply h-full w-full;
  touch-action: none;
}

.swiper {
  @apply flex flex-col pb-8;
}

.swiper-slide {
  @apply relative mb-8 flex items-center justify-center;
}

.swiper-slide-wrapper {
  @apply pointer-events-none absolute top-0 z-20 w-full border border-gray;
  height: calc(100% - 2rem);
}

.swiper-pagination {
  @apply absolute bottom-0 z-10 flex h-8 w-full items-center justify-center space-x-2;
}

.swiper-pagination-bullet {
  @apply h-0.5 w-4 rounded-none bg-gray;
}

.swiper-pagination-bullet-active {
  @apply bg-red;
  height: 0.2rem;
}
