.richText ul {
  @apply mb-8 list-disc pt-1;
}

.richText ul > li {
  @apply mb-2 ml-7 leading-7;
}
.richText ul > li a {
  @apply font-normal underline;
}

.richText ol {
  @apply mb-8 list-decimal pt-1;
}

.richText ol > li {
  @apply mb-2 ml-7 leading-7;
}

.richText small {
  @apply mb-2;
}

.richText p {
  @apply mb-6 sm:mb-4;
}

.richText h3,
h4 {
  @apply mb-4;
}

.richText pre {
  @apply overflow-x-auto;
}
